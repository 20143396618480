

















































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import ExpressAndOutlets from '@/model/ExpressAndOutlets';
import ExpressPoint from '@/model/ExpressPoint';
import InsInput from '@/components/base/pc/InsInput.vue';
import InsInput2 from '@/components/base/pc/InsInput2.vue';
import PickupAddress from '@/model/pickupAddress';
import Address from '@/model/address';
import { Province } from '@/model/province';
import { Country } from '@/model/country';
import InsButton from '@/components/base/pc/InsButton.vue';
import InsSelect from '@/components/base/pc/InsSelect2.vue';
import InsForm from '@/components/base/pc/InsForm.vue';
import { MemberResult } from '@/model/memberResult';
import Collaspe from '@/components/service/Collapse.vue';
class ExpressChargeReq {
  ItemAmount!:number;
  PointId!:string;
  constructor (ItemAmount:number, PointId:string) {
    this.ItemAmount = ItemAmount;
    this.PointId = PointId;
  }
}
@Component({ components: { InsInput, InsButton, InsSelect, InsInput2, InsForm, Collaspe } })
export default class InsExpressWay extends Vue {
    private pickupTimeList;
    // 運輸方式列
    private Express:ExpressAndOutlets[] = [];
    // 選中的運輸方式
    private ChosenExpress:ExpressAndOutlets = new ExpressAndOutlets();
    // 快遞點
    private ChosenExpressPoint:ExpressPoint = new ExpressPoint();
    // 門店自提地址列表
    private PickupAddressList: PickupAddress[] = [];
    // 門店自提地址
    private PickAddress: PickupAddress = new PickupAddress();
    // 當前自提地址
    private CurrentPickupAddress: PickupAddress = new PickupAddress();
    // 加載中
    private loading: boolean = true;
    // 選擇運輸方式為送貨上門后，是否可選快遞的運輸方式
    private chooseCharge: boolean = false;
    // 可選快遞的運輸方式列表
    private chooseChargeList: ExpressAndOutlets[] = [];
    // 選中的送貨上門地址
    private SelectedAddress:Address = new Address();
    // 是否有信息異常
    private expressError: boolean = false;
    // 當前正在編輯的地址
    private editAddress:Address = new Address();
    // 商品總價
    private ItemsAmount:number = 0;
    // 總重量
    private TotalWeight:number = 0;
    // 省份列表
    private provinceList: Province[] = [];
    // 選中快遞方式價格
    private ChosenExpressCharge: ExpressAndOutlets = new ExpressAndOutlets();
    // 地址列表
    private addressList:Address[] = [];
    // 国家地区列表
    private countryList: Country[] = [];
    // 用户信息
    private Profile = new MemberResult();
    // 是否顯示編輯地址
    private showEdit = false;
    @Prop({ default: false }) private lockFare!: boolean;
    created () {
      this.pickupTimeList = [{ Id: '0', Name: this.$t('CheckOut.Morning') }, { Id: '1', Name: this.$t('CheckOut.Afternoon') }];
      if (this.lockFare) { this.loading = false; return; }
      this.$store.dispatch('setPickUpExpress', true);
      let profile = this.$Api.member.getProfile2().then((result) => {
        this.Profile = result.MemberResult;
        // this.$store.dispatch('setMemberInfo', result.MemberResult);
        this.PickAddress.Name = this.Profile.LastName ? this.Profile.FirstName + ' ' + this.Profile.LastName : this.Profile.FirstName;
        this.PickAddress.Phone = this.Profile.Mobile;
        this.PickName = this.PickAddress.Name;
        this.PickPhone = this.PickAddress.Phone;
      });
      let express = this.$Api.delivery.getExpressAndOutlets().then((result) => {
        this.Express = result.ExpressAndOutlets;
        this.ChosenExpress = result.ExpressAndOutlets.length > 0 ? result.ExpressAndOutlets[0] : new ExpressAndOutlets();
        this.ChosenExpressPoint = this.ChosenExpress.ExpressPointList.length ? this.ChosenExpress.ExpressPointList[0] : new ExpressPoint();
      });
      if (!this.$store.state.shopCart) this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
      let shopcart = this.$store.state.shopCart.then((result) => {
        this.TotalWeight = result.ShopCart.TotalWeight;
        this.ItemsAmount = result.ShopCart.ItemsAmount;
      });
    }
    @Watch('PickAddress', { deep: true })
    onPickAddressChange () {
      this.Shake(() => {
        if (!this.PickAddress.Phone || !this.PickAddress.Name || !this.PickAddress.PD) { this.$emit('PickAddressError'); } else {
          this.$emit('PickAddress');
          this.$store.dispatch('setPickupAddress', this.PickAddress);
        }
      }, 500);
    }
    @Watch('ChosenExpress')
    onChosenExpressChange () {
      this.loading = true;
      this.chooseCharge = false;
      this.$store.dispatch('setPickUpExpress', this.ChosenExpress.IsExpressPoint);
      if (!this.$store.state.pickUpExpress) {
        // 如果是送貨上門就加載可用地址
        this.loadAddress();
        this.ChosenExpressPoint = new ExpressPoint('', '', '-1');
        this.$store.dispatch('setDeliveryType', 'D');
        this.$Api.delivery.getDefaultAddrForEx(this.ChosenExpress.Id).then((result) => {
          this.editAddress = new Address();
          this.SelectedAddress = result.Address;
          if (this.SelectedAddress.DeliveryId) this.$store.dispatch('setSelectAddress', this.SelectedAddress);
          else throw new Error('no default address');
        }).catch(async (e) => {
          // 这里应该是要处理没有默认地址的，获取当前快递支持的用户地址的第一个
          await this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
            this.editAddress = new Address();
            this.SelectedAddress = result.Address.length > 0 ? result.Address[0] : new Address();
            this.$store.dispatch('setSelectAddress', this.SelectedAddress);
            if (result.Address.length === 0) this.showEdit = true;
          });
        }).then(() => {
          this.getExpressChargeForEx();
          this.$emit('express'); this.expressError = true;
        });
      } else {
        // 快遞自提
        if (this.ChosenExpress.ExpressCompanyId !== 'P') {
          this.ChosenExpressPoint = this.ChosenExpress.ExpressPointList.length ? this.ChosenExpress.ExpressPointList[0] : new ExpressPoint('', '', '-1');
          this.$store.dispatch('setDeliveryType', 'D');
          this.$store.dispatch('setExpress', this.ChosenExpress);
          if (this.ChosenExpress.ExpressPointList.length === 0 || this.PickPhone === null || this.PickPhone === undefined || !this.PickName) { this.$emit('PickAddressError'); this.expressError = false; this.loading = false; } else { this.$emit('express'); this.expressError = true; }
        } else if (this.ChosenExpress.ExpressCompanyId === 'P') {
          this.$store.dispatch('setExpress', this.ChosenExpress);
          this.ChosenExpressPoint = new ExpressPoint();
          this.$store.dispatch('setDeliveryType', 'P');
          this.$Api.delivery.getPickupAddressV2().then((result) => {
            this.$emit('express');
            this.loading = false;
            this.PickupAddressList = result.PickupAddress;
            if (this.PickupAddressList.length > 0) this.CurrentPickupAddress = this.PickupAddressList[0]; else this.CurrentPickupAddress = new PickupAddress();
            this.PickAddress.Id = this.CurrentPickupAddress.Id;
            this.PickAddress.CompanyAddress = this.CurrentPickupAddress.Address;
          });
        }
      }
    }
    @Watch('ChosenExpressPoint')
    onChosenExpressPoint () {
      if (this.ChosenExpressPoint.Id === '-1') return;
      this.loading = true;
      this.$Api.delivery.getExpressPointCharge(new ExpressChargeReq(this.ItemsAmount, this.ChosenExpressPoint.Id)).then(
        (result) => {
          this.ChosenExpressPoint.DiscountPrice = result;
          this.$emit('express');
          this.$store.dispatch('setExpressPoint', this.ChosenExpressPoint);
          this.loading = false;
        }
      );
    }
    @Watch('editAddress.Country')
    onCountryChange (o, n) {
      if (this.editAddress.Country.Id !== '') {
        this.$Api.delivery.getProvinceForEx(this.ChosenExpress.Id, this.editAddress.Country.Id).then((result) => {
          this.provinceList = result.Province;
        });
      } else {
        this.provinceList = [];
      }
      // this.provinceList = this.allProvice[this.editAddress.Country.Id];
      // if (!Array.isArray(this.provinceList)) {
      // if (o.Id === n.Id) return;
      // this.$Api.delivery.getProvinceForEx(this.ChosenExpress.Id, this.editAddress.Country.Id).then((result) => {
      //   this.provinceList = result.Province;
      //   // this.allProvice[this.editAddress.Country.Id] = result.Province;
      // });
      // }
    }
    @Watch('SelectedAddress')
    onSelectedAddressChange () {
      this.Shake(this.getExpressChargeForEx);
    }
    getExpressChargeForEx () {
      if (this.SelectedAddress.DeliveryId === 0) { this.$SingtonConfirm(this.$t('Message.Message'), this.$t('DeliveryAddress.AddDeliveryAddress')); this.$emit('expressError'); this.expressError = false; this.loading = false; return; };
      this.$Api.delivery.getExpressChargeForEx({
        DeliveryAddrId: this.SelectedAddress.DeliveryId,
        TotalWeight: this.TotalWeight,
        ItemAmount: this.ItemsAmount,
        ExpressId: this.ChosenExpress.Id }).then((result) => {
        this.loading = false;
        if (result.ExpressAndOutlets.length === 0) {
          this.$SingtonConfirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); this.$emit('expressError'); this.expressError = false;
        } else if (result.ExpressAndOutlets.length === 1) {
          this.$store.dispatch('setExpress', result.ExpressAndOutlets[0]);
          this.$emit('express');
          this.expressError = true;
        } else if (result.ExpressAndOutlets.length > 1) {
          this.$store.dispatch('setExpress', result.ExpressAndOutlets[0]);
          this.$emit('express');
          this.expressError = true;
          this.chooseCharge = true;
          this.chooseChargeList = result.ExpressAndOutlets;
          this.ChosenExpressCharge = result.ExpressAndOutlets[0];
        } else { this.$emit('expressError', this.$t('CheckOut.expressError')); this.expressError = false; }
      });
    }
    @Watch('ChosenExpressCharge')
    onChoseExpressChargeChange () {
      this.$store.dispatch('setExpress', this.ChosenExpressCharge);
    }
    loadAddress () {
      // if (!this.expressError) { this.$SingtonConfirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); return; }
      this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
        this.addressList = result.Address;
        if (this.addressList && this.addressList.length === 0) this.showEdit = true;
      });
      this.$Api.delivery.getCountyForEx(this.ChosenExpress.Id).then((result) => {
        this.countryList = result.Country;
      }).then(() => {
        this.$Api.delivery.getProvinceForEx(this.ChosenExpress.Id, this.editAddress.Country.Id).then((result) => {
          this.provinceList = result.Province;
        });
      });
    }
    editAddr (index) {
      this.editAddress = Object.assign(new Address(), this.addressList[index]);
      this.showEdit = true;
    }
    removeAddr (index) {
      if (this.addressList[index] && this.addressList[index].DeliveryId === this.SelectedAddress.DeliveryId) {
        // this.$SingtonConfirm(this.$t('Message.Message'), this.$t('CheckOut.AddressLock')); return;
        this.SelectedAddress = new Address();
        this.$store.dispatch('setSelectAddress', this.SelectedAddress);
      }
      let address = this.addressList.splice(index, 1);
      if (address.length) { this.$Api.address.removeAddress(address[0].DeliveryId); }
    }
    selectAddr (index) {
      this.loading = true;
      this.SelectedAddress = this.addressList[index];
      this.$store.dispatch('setSelectAddress', this.SelectedAddress);
      this.$message(this.$t('Message.SucceedInOperating') as string);
    }
    addAddr () {
      this.editAddress = new Address(this.countryList[0], this.provinceList[0]);
      this.showEdit = true;
    }
    save (formName) {
      let names = this.editAddress.FullName.trim().split(/\s+/);
      let fistName;
      let lastName;
      if (names.length > 1) {
        fistName = names[0];
        lastName = names[1];
      } else {
        fistName = names[0];
        lastName = '';
      }
      if (this.provinceList.length === 0) {
        this.editAddress.Provinceo.Id = -1;
        this.editAddress.Provinceo.Name = '';
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.editAddress.Provinceo.Id;
      }
      let form = {
        Province: this.editAddress.Provinceo.Id,
        CountryId: this.editAddress.Country.Id,
        ProvinceName: this.editAddress.Provinceo.Name,
        DeliveryId: this.editAddress.DeliveryId === 0 ? undefined : this.editAddress.DeliveryId,
        Default: this.editAddress.Default,
        IsUsable: true,
        FirstName: fistName,
        LastName: lastName,
        Phone: this.editAddress.Phone,
        Mobile: this.editAddress.Mobile,
        PostalCode: this.editAddress.PostalCode,
        Address: this.editAddress.Address,
        MemberId: this.Profile.MemberId
      };
      setTimeout(() => {
        (this.$refs[formName] as InsForm).validate((valid) => {
          if (valid) {
            this.$Api.address.saveAddress(form).then((result) => {
            }).then(() => {
              this.$Api.delivery.getAddressForEx(this.ChosenExpress.Id).then((result) => {
                this.addressList = result.Address;
                this.SelectedAddress = result.Address.length > 0 ? result.Address[0] : new Address();
                this.$store.dispatch('setSelectAddress', this.SelectedAddress);
                this.$message(this.$t('Message.SucceedInOperating') as string);
                (this.$refs.adderform as InsForm).reset();
                this.showEdit = false;
              });
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }, 500);
    }
    get PickName () {
      return this.$store.state.DPickName;
    }
    set PickName (v) {
      this.Shake(() => {
        if (!v) { this.$emit('PickAddressError'); } else {
          this.$emit('PickAddress');
          this.$store.dispatch('setPickupAddress', this.PickAddress);
        }
      }, 100);
      this.$store.dispatch('setDPickName', v);
    }
    get PickPhone () {
      return this.$store.state.DPickPhone;
    }
    set PickPhone (v) {
      this.Shake(() => {
        if (!v) { this.$emit('PickAddressError'); } else {
          this.$emit('PickAddress');
          this.$store.dispatch('setPickupAddress', this.PickAddress);
        }
      }, 100);
      this.$store.dispatch('setDPickPhone', v);
    }
    ExpressSelect () {
      (this.$refs.adderform as InsForm).reset();
      this.showEdit = false;
    }
}












import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
// import ShoppingCartItem from '@/components/business/mobile/account/InsShoppingcartItem.vue';
import ShopCart from '@/model/ShopCart';
import Currency from '@/model/currency';
class Update {
  itemId!: string;
  qty!: number;
  constructor (itemId:string, qty:number) {
    this.itemId = itemId;
    this.qty = qty;
  }
}
@Component({ components: { ShoppingCartItem: () => import(/* webpackChunkName: "checkout" */ '@/components/business/mobile/account/InsShoppingcartItem.vue') } })
export default class InsShoppingCart extends Vue {
    Currency = Currency;
    @Prop() private readonly lockShoppingcart!:boolean;
    @Prop() private readonly cleanUpdate!:boolean;
    private Shoppcart:ShopCart = new ShopCart();
    private totalAmount:number = 0;
    private UpdateQueQue:Update[] = [];
    created () {
      if (!this.cleanUpdate) { this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart()); }
      this.$store.state.shopCart.then((result) => {
        this.Shoppcart = result.ShopCart;
        this.totalAmount = result.ShopCart.TotalAmount;
        if (this.Shoppcart.Items.length === 0) Vue.prototype.$Confirm(this.$t('Message.Message'), this.$t('Shoppingcart.None'), () => { this.$router.push('/product/search/+'); }, () => { this.$router.push('/'); });
      });
    }
    loadItems () {
      var itemsprice = 0;
      var Currencys;
      this.Shoppcart.Items.forEach(element => {
        itemsprice += element.Product.SalePrice * element.Qty;
      });
      this.totalAmount = itemsprice;
    }
  @Watch('Shoppcart.Items', { deep: true })
    onItemsChange (o, n) {
      this.loadItems();
    }
  updateQty (index, id) {
    // this.UpdateQueQue.push(new Update(id, this.Shoppcart.Items[index].Qty));
    this.Shake(() => {
      this.$Api.shoppingCart.updateItemQty(id, this.Shoppcart.Items[index].Qty).then(() => {
        this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
      });
    }, 500);
  }
  removeItem (index, id) {
    this.Shoppcart.Items.splice(index, 1);
    this.$Api.shoppingCart.removeItem(id).then(result => {
      this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
      if (this.Shoppcart.Items.length === 0) Vue.prototype.$Confirm(this.$t('Message.Message'), this.$t('Shoppingcart.None'), () => { this.$router.push('/product/search/+'); }, () => { this.$router.push('/'); });
    });
  }
  checkout () {
    let temp = {};
    let item:Update;
    let waittingList = [Promise.resolve('head')];
    waittingList.length = 0;
    while (this.UpdateQueQue.length !== 0) {
      item = this.UpdateQueQue.shift() as Update;
      temp[item.itemId] = item.qty;
    }
    Object.keys(temp).forEach((element) => {
      waittingList.push(this.$Api.shoppingCart.updateItemQty(element, temp[element]));
    });
    Promise.all(waittingList).then((result) => {
      this.$router.push('/account/checkout');
    });
  }
}

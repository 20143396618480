





















































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
// import ShoppingCartItem from '@/components/business/pc/account/InsShoppingcartItem.vue';
import ShopCart from '@/model/ShopCart';
// import Collaspe from '@/components/service/Collapse.vue';
import PaymentM from '@/model/payment';
import InsExpressWay from '@/components/business/pc/account/InsExpressWay.vue';
import Order, { CreateOrder } from '@/model/order';
import PickupAddress from '@/model/pickupAddress';
import PromotionDiscount from '@/model/PromotionDiscount';
import Express from '@/model/express';
import Coupon from '@/model/coupon';
// import InsCoupon from '@/components/temp/InsCoupon.vue';
// import InsButton from '@/components/base/pc/InsButton.vue';
@Component({ components: { ShoppingCartItem: () => import(/* webpackChunkName: "checkout" */ '@/components/business/pc/account/InsShoppingcartItem.vue'),
  Collaspe: () => import(/* webpackChunkName: "checkout" */ '@/components/service/Collapse.vue'),
  InsExpressWay: () => import(/* webpackChunkName: "checkout" */ '@/components/business/pc/account/InsExpressWay.vue'),
  InsButton: () => import(/* webpackChunkName: "checkout" */ '@/components/base/pc/InsButton.vue') } })
export default class InsCheckoutN extends Vue {
    private Shoppcart:ShopCart = new ShopCart();
    private totalAmount:number = 0;
    private allShopCart:boolean = false;
    private payment: PaymentM = new PaymentM();
    private payments:PaymentM[] = [new PaymentM()];
    private orderId:number = 0;
    private delete1: any = [];
    private promotionCode:string = '';
    private checkouting = true;
    private express:boolean = false;
    private PickAddress: boolean = false;
    // 優惠券相關
    private coupon:Coupon[] = [];
    private selectedCoupon:Coupon[] = [];
    private multiple:Coupon[] = [];
    private single:Coupon[] = [];
    private all = {};
    private currentPrice: number = 0;
    private ll: boolean = false;
    private exp: number = 0;

    // 點擊創建訂單后loading
    COloading = false;
    private showDistcount :boolean = false;
    data () {
      return {
        orderSure: false
      };
    }
    Hyperlink () {
      // window.location.href = '/account/shoppingcart';
      this.$router.push('/account/shoppingcart');
    }
    created () {
      this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
      this.$store.dispatch('setAddress', this.$Api.checkout.getAddress2());
      let pm = this.$Api.checkout.getPaymentMethod().then(result => {
        this.payments = result.Payment;
      });
      Promise.all([this.$store.state.shopCart, this.$store.state.address, pm]).then((result) => {
        let sc = result[0];
        this.totalAmount = sc.ShopCart.TotalAmount;
        this.currentPrice = this.totalP;
        this.Shoppcart = sc.ShopCart;
        this.$HiddenLayer();
        this.$Api.member.getActiveCoupon({ Page: 1, PageSize: 10 }).then((result) => {
          this.coupon = result.Coupon;
          this.coupon.forEach((element) => {
            if (element.MeetAmount >= this.totalP) return;
            element.canCheck = false;
            this.$set(this.all, element.Id, element);
            if (element.IsAdditional === true) this.multiple.push(element);
            else this.single.push(element);
          });
        });
      });
    }
    get items () {
      if (this.allShopCart) return this.Shoppcart.Items.length;
      else return this.Shoppcart.Items.length > 3 ? 3 : this.Shoppcart.Items.length;
    }
    checkOut () {
      if (this.$store.state.DeliveryType === 'D' && !this.express) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.expressError')); return; }
      if (this.$store.state.DeliveryType === 'P' && !this.PickAddress) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.PickAddressError')); return; } else if (this.$store.state.DeliveryType === 'P' && this.PickAddress) {
        this.$Api.delivery.savePickupInfo(this.$store.state.pickupAddress.Id, this.$store.state.pickupAddress.CompanyAddress);
      }
      if (this.$store.state.DeliveryType === 'D' && !this.PickAddress && this.$store.state.pickUpExpress) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.PickAddressError')); return; }
      if (this.payment !== undefined) {
        this.$Api.paymentApi.savePayMethod(this.payment.Id).then((result) => {
          this.pay();
          this.$store.dispatch('setPaymentMethod', this.payment);
        });
      } else {
        this.$Confirm(this.$t('Message.Message'));
      }
    }
    pay () {
      let order;
      let profile = this.$store.state.memberInfo;
      if (this.$store.state.DeliveryType === 'D') order = new CreateOrder(this.$store.state.pickUpExpress ? -1 : this.$store.state.selectAddress.DeliveryId, this.$store.state.express.Id, this.payment.Id, 'D', '', '', this.$store.state.DPickPhone, this.$store.state.DPickName, this.promotionCode, this.$store.state.expressPoint.Id, this.delete1);
      else if (this.$store.state.DeliveryType === 'P') order = new CreateOrder(this.$store.state.pickupAddress.Id, '', this.payment.Id, 'P', this.$store.state.pickupAddress.PickupDate, this.$store.state.pickupAddress.PickupTime, this.$store.state.pickupAddress.Phone, this.$store.state.pickupAddress.Name, this.promotionCode, '', this.delete1);
      // if ((!order.PickupName && !profile.FirstName) && (!order.Mobile && !profile.PickupPhone)) { // 若無姓名與電話， 則updateprofile
      //   this.$Confirm(
      //     this.$t('Message.Message'),
      //     this.$t('CheckOut.Use') + ' ' + order.PickupName + ' ' + this.$t('CheckOut.As') + ' ' + this.$t('CheckOut.Name') + '</br>' +
      //   this.$t('CheckOut.Use') + ' ' + order.PickupPhone + ' ' + this.$t('CheckOut.As') + ' ' + this.$t('CheckOut.Phone'),
      //     () => {
      //       let pn = order.PickupName.split(' ');
      //       if (pn.length > 1) {
      //         profile.FirstName = pn[0];
      //         profile.LastName = pn[1];
      //       } else {
      //         profile.FirstName = order.PickupName;
      //         profile.LastName = order.PickupName;
      //       }
      //       profile.Mobile = order.PickupPhone;
      //       this.updateProfile(profile).then(() => { this.createOrder(order); });
      //     },
      //     () => { this.createOrder(order); }
      //   );
      // } else if ((!order.PickupName && !profile.FirstName) && (!order.PickupPhone && profile.Mobile)) { // 若無姓名， 則updateprofile
      //   this.$Confirm(
      //     this.$t('Message.Message'),
      //     this.$t('CheckOut.Use') + ' ' + order.PickupName + ' ' + this.$t('CheckOut.As') + ' ' + this.$t('CheckOut.Name'),
      //     () => {
      //       let pn = order.PickupName.split(' ');
      //       if (pn.length > 1) {
      //         profile.FirstName = pn[0];
      //         profile.LastName = pn[1];
      //       } else {
      //         profile.FirstName = order.PickupName;
      //         profile.LastName = order.PickupName;
      //       }
      //       this.updateProfile(profile).then(() => { this.createOrder(order); });
      //     },
      //     () => { this.createOrder(order); }
      //   );
      // } else if ((!order.PickupName && profile.FirstName) && (!order.PickupPhone && !profile.Mobile)) { // 若無電話， 則updateprofile
      //   this.$Confirm(
      //     this.$t('Message.Message'),
      //     this.$t('CheckOut.Use') + ' ' + order.PickupPhone + ' ' + this.$t('CheckOut.As') + ' ' + this.$t('CheckOut.Phone'),
      //     () => { profile.Mobile = order.PickupPhone; this.updateProfile(profile).then(() => { this.createOrder(order); }); },
      //     () => { this.createOrder(order); }
      //   );
      // } else {
      //   this.createOrder(order);
      // }
      this.createOrder(order);
    }
    updateProfile (profile) {
      if (profile.Gender === '') profile.Gender = '0';
      return this.$Api.member.updateProfile(profile);
    }
    createOrder (order) {
      this.COloading = true;
      this.$Api.order.createOrder(
        order
      ).then((result) => {
        if (result.Succeeded) {
          this.orderId = result.ReturnValue;
          this.$store.dispatch('setDeliveryType', 'D');
          this.$store.dispatch('setExpress', new Express());
          this.$store.dispatch('setPickupAddress', new PickupAddress());
          this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
          this.$router.push({ name: 'completeCheckout', params: { id: result.ReturnValue } });
          this.COloading = false;
        } else { Vue.prototype.$Confirm('error', result.Message); }
      });
    }
    setPromotionCode (code) {
      this.promotionCode = code;
    }
    checkChange (item) {
      let canPush = true;
      this.selectedCoupon.forEach((e, index) => {
        if (e.Id === item.Id) {
          canPush = false;
          this.selectedCoupon.splice(index, 1);
        }
      });
      if (canPush) this.selectedCoupon.push(item);
    }
  @Watch('delete1', { deep: true })
    onSelectedChange (n, old) {
      if (n.length === 0) {
        this.single.forEach(element => {
          element.canCheck = false;
        });
        this.multiple.forEach(element => {
          element.canCheck = false;
        });
        if (this.ll) { this.ll = false; return; }
      }
      let current = this.delete1[this.delete1.length - 1];
      if (old.length === 0 && n.length === 1) {
        if (this.all[current].IsAdditional === true) {
          this.single.forEach(element => {
            if (this.all[current].Id !== element.Id) { element.canCheck = true; }
          });
        } else {
          this.single.forEach(element => {
            if (this.all[current].Id !== element.Id) { element.canCheck = true; }
          });
          this.multiple.forEach(element => {
            element.canCheck = true;
          });
        }
      }
      // 計算多個優惠
      let mult:any[] = [];
      let temp = this.totalP;
      this.currentPrice = this.totalP;
      this.delete1.forEach(element => {
        if (this.all[element].IsDiscount) mult.push(this.all[element]);
        else {
          temp = this.currentPrice - this.all[element].DiscountAmount;
        }
      });
      mult.forEach((element) => {
        temp -= this.currentPrice * (element.DiscountAmount / 100);
      });
      this.currentPrice = temp;
    }
  get totalP () {
    this.currentPrice = this.totalAmount;
    return this.totalAmount;
  }
  get exdp () {
    this.exp = this.$store.state.express.DiscountPrice;
    return Number(this.$store.state.express.DiscountPrice as string);
  }
  @Watch('exp')
  onExpChange () {
    // this.ll = true;
    if (this.delete1.length !== 0) { this.delete1 = []; };
    if (this.selectedCoupon.length !== 0) { this.selectedCoupon = []; };
  }
  @Watch('payments')
  onPaymentMethodsChange (o, n) {
    if (this.payments.length > 0) this.payment = this.payments[0];
  }
  get discount () {
    return this.$store.state.discount;
  }
  get cp () {
    return this.discount.Id === '-1'
      ? this.currentPrice
      : (this.discount.IsDiscount ? ((this.currentPrice) * (1 - this.discount.Favorable / 100)).toFixed(2) : (this.currentPrice) - this.discount.Favorable);
  }
  get currentCode () {
    return this.$store.state.currency.Code;
  }
  searchCoupon () {
    if (this.totalAmount !== this.currentPrice) { this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.CandP')); return; }
    this.$Api.order.getPromotionCodeFrontView(this.promotionCode).then((result) => {
      this.$store.dispatch('setPromotionDiscount', result.PromotionDiscount);
      this.$emit('promotionCode', this.promotionCode);
      this.showDistcount = true;
    }).catch((error) => {
      console.log(this.$t('Message.Message'), error);
      this.$Confirm(this.$t('Message.Message'), this.$t('CheckOut.promotionCodeError'));
    });
  }
  @Watch('promotionCode')
  onPromotionCode () {
    if (!this.promotionCode) {
      this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
      this.$emit('promotionCode', '');
      this.showDistcount = false;
    }
  }
}

























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import ProductAttr from '@/model/ProductAttr';
@Component
export default class InsSelect2 extends Vue {
  // data
  private Value: string = '';
  private RealValue!: ProductAttr;
  private None: boolean = false;
  private Content: string[] = [];
  private DropdownShow: boolean = false;
  @Prop({ default: '' }) private Placeholder !:string;
  @Prop({ default: false }) private must !:boolean;
  //   props
  @Prop() private readonly label!: string;
  @Prop() private readonly styla!: string;
  @Prop({ default: 'Name' }) private readonly name!: string;
  @Prop() private readonly items!: ProductAttr[];
  @Prop() private readonly value!: any;
  @Prop({ default: '120px' }) private labelWidth!:string;
  @Prop({ default: false }) disabled!: boolean;
  //   method
  choice (e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.nodeName !== 'LI') {
      return;
    }
    this.Value = this.items[target.dataset.key as string][this.name];
    this.RealValue = this.items[target.dataset.key as string];
    this.DropdownShow = false;
    this.$emit('input', this.RealValue);
  }
  leftDropdown (e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target.nodeName === 'DIV') {
      this.DropdownShow = false;
    }
  }
  //   created
  created () {
    if (!this.items || this.items.length === 0) return;
    // if (this.value) {
    //   if (!this.items[this.value]) return;
    //   this.Value = this.items[this.value][this.name];
    // } else if (this.items.length > 0 && !this.value) {
    //   this.Value = this.items[0][this.name];
    //   this.RealValue = this.items[0];
    //   this.$emit('input', this.RealValue);
    // }
    if (this.items && this.items.length > 0) {
      this.Value = this.items[0][this.name];
      this.RealValue = this.items[0];
      this.$emit('input', this.RealValue);
    }
  }
  @Watch('items', { deep: true })
  onItemsChange () {
    if (this.items && this.items.length > 0) {
      this.Value = this.items[0][this.name];
      this.RealValue = this.items[0];
      this.$emit('input', this.RealValue);
    }
  }
  @Watch('value')
  onvalueChange () {
    if (!this.items) return;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i][this.name] === this.value[this.name]) {
        this.Value = this.value[this.name];
        this.RealValue = this.value;
        break;
      }
    }
    this.$emit('input', this.RealValue);
  }
  click () {
    if (this.disabled) return;
    this.DropdownShow = !this.DropdownShow;
  }
}

















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import ShopcartItem from '@/model/shopCartItem';
import inNum from '@/components/base/mobile/InsNum.vue';
import Currency from '@/model/currency';
@Component({ components: { inNum } })
export default class InsShoppingcartItem extends Vue {
    Currency = Currency;
    @Prop() private readonly ShopcartItem !:ShopcartItem;
    @Prop() private readonly index !:number;
    @Prop() private readonly lock !:boolean;
    @Watch('ShopcartItem.Qty', { deep: true })
    QtyChange () {
      this.$emit('QtyChange', this.index, this.ShopcartItem.Id);
    }
    removeItem () {
      this.$emit('removeItem', this.index, this.ShopcartItem.Id);
    }
}
